<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <filtrador
          v-on:dato-actualizado="
            dato_cliente = $event,
            refrescarClientes()
          "
          :datoini="dato_cliente"
          placeholder="Dato de la solicitud"
        ></filtrador>
        <div class="table-responsive">
          <table class="table table-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th style="width: 140px;" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cliente in clientes" :key="cliente.id">
                <td>{{ cliente.id }}</td>
                <td>
                  {{ cliente.nombre }}
                  {{ cliente.apellido_paterno }}
                  {{ cliente.apellido_materno }}
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-success btn-sm"
                    @click="seleccionar(cliente)"
                  >
                    <i class="fa fa-check"></i>
                    Seleccionar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="text-right">
          <button class="btn btn-danger" @click="cancelar()">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
export default {
  name: 'CmpSeleccionCliente',
  components: { Filtrador },
  data() {
    return {
      dato_cliente: '',
      clientes: []
    }
  },
  methods: {
    cancelar: function() {
      this.$emit('cancelar')
    },

    refrescarClientes: function() {
      var self = this

      if (self.dato_cliente == '') {
        iu.msg.warning('Es necesario un dato del cliente para hacer el filtrado')
        return
      }

      var params= { 
        dato_cliente: self.dato_cliente,
        activos: true 
      }

      ClienteSrv.clientesJSON(params).then(response => {
        self.clientes = response.data.clientes
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    seleccionar: function(cliente) {
      this.$emit('cliente-seleccionado', cliente)
    }
  }
}
</script>

<style scoped>

</style>