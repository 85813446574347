/**
 * Edición de solicitud de instalación
 */
import Layout from '@/views/layouts/main'
import { GoogleMap, Marker } from "vue3-google-map"
import markerIcon from '@/assets/img/location24x36.png'
import CmpSeleccionCliente from './CmpSeleccionCliente.vue'
import SolicitudInstalacionSrv from '@/services/SolicitudInstalacionSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import ProductoSrv from '@/services/ProductoSrv.js'
import MetodoPagoSrv from '@/services/MetodoPagoSrv.js'
import { todoGetters } from "@/state/helpers";

import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'
import md5 from 'md5'

export default {
  name: 'EdicionSolicitudInstalacion',
  components: { 
    Layout,
    CmpSeleccionCliente,
    GoogleMap,
    Marker,
    // Datepicker
  },
  props: ['id'],
  data() {
    return {
      API: API,
      baseUrl: location.origin,
      tk: localStorage.getItem('argusblack.token'),
      modo: 'nueva', // Los modos son 'nueva' o 'edicion'
      pIva: 0, // Porcentaje de IVA
      clienteNuevo: 1,
      seleccionCliente: 0,
      markerIcon:markerIcon,
      mapaZoom: 12,
      posicionInicial: {
        lat: 0,
        lng: 0
      },
      lat_lng: 0,
      bloquear_lat_lng: true,
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],
      servicios: [],
      planes: [],
      direccionInstalacionIgualADireccionCliente: 1,
      metodosPago: [],
      solicitud: {
        id: null,

        id_estado: 1,
        nuevo_id_estado: 1, // Se usa para determinar si hubo una actualización o no

        id_servicio: null,
        meses_incluidos: 0,
        anticipo: 0,
        importe: 0,
        id_metodo_pago: 1,   // Efectivo
        motivo_cancelacion: '',

        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        sexo: 'hombre',
        rfc: '',
        telefono: '',
        correo: '',
        empresa: 0,

        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado_residencia: '',
        pais: '',

        calle_instalacion: '',
        numero_exterior_instalacion: '',
        numero_interior_instalacion: '',
        colonia_instalacion: '',
        municipio_instalacion: '',
        codigo_postal_instalacion: '',
        estado_residencia_instalacion: '',
        pais_instalacion: '',

        latitud: 0,
        longitud: 0,

        referencias: '',
        descripcion_casa: '',

        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,

        telefonos: [
          // {
          //     prefijo: '',
          //     numero: ''
          //     tipo: ''
          // },
          // ...
        ]
      },

      tipoTelefono: 'celular',
      numeroTelefono: '',
      telefonos: [],
      prefijo: null,
      paises: [],
      solicitudInicial: null,
      bandera_spinner: false
    }
  },
  computed: {
    ...todoGetters,
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    },
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this // Promesa del API Key de google
        
    // Copia inicial del objeto "solicitud"
    this.solicitudInicial = Object.assign({}, this.solicitud)

    // Asignación del modo de la solicitud
    this.asignarModo()

    // Si el modo es "edicion", no se oculta la dirección de instalación
    if (this.modo == 'edicion')
      self.direccionInstalacionIgualADireccionCliente = false

    if (self.$route.query.fecha_programada) self.solicitud.fecha_programada = moment(self.$route.query.fecha_programada).format('YYYY-MM-DD HH:mm:ss')
    if (self.$route.query.tiempo_estimado) self.solicitud.tiempo_estimado = self.$route.query.tiempo_estimado

    // Cargas iniciales
    self.cargarPlanes()
    self.cargarPaises()
    self.cargarPrefijoTelefonico()
    self.cargarMetodosPago()


    if (self.modo == 'nueva') {
      // Si el modo es "nueva"
      self.cargarMesesIncluidos()
      self.cargarPrecioInstalacion()
    }
  },
  mounted: function() {
    var self = this,
        solicitudProm
    
    // Si el modo es "edicion"
    if (self.modo == 'edicion') {
      solicitudProm = self.cargarSolicitud() // Carga de la solicitud de instalación

      solicitudProm.then(response => {
        self.iniciarMapa()
        if (self.solicitud.id_cliente != null) {
          self.clienteNuevo = 0
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    } else {
      self.iniciarMapa()
    }
    // Selección del primer plan si el modo es "nueva"
    if (self.modo == 'nueva' && self.planes.length > 0) {
      self.solicitud.id_servicio = self.planes[0].id
    }
    // Si el modo es "edicion", se seleccióna el plan de la solicitud
    if (self.modo == 'edicion' && self.planes.length > 0) {
      let index = self.planes.findIndex(function(plan) {
        return plan.id == self.solicitud.id_servicio
      })

      // Si no se encontró el plan, se procede a cargarlo
      if (index == -1) {
        ProductoSrv.planServicioJSON(self.solicitud.id_servicio).then(response => {
          let plan = response.data
          self.planes.push(plan)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje) : null
          console.log(error)
        })
      }
    }
    
  },
  methods: {
    actualizar: function() {
      var self = this
      self.bandera_spinner = true
      // Si no se pasó la verificacion de datos correctos, entonces se suspende la actualización
      if (!self.verificacionDatosCorrectos()) return

      var solicitud = Object.assign({}, self.solicitud)

      // convertimos el formato fecha al formato de fecha deseado
      solicitud.fecha_programada = self.formatoFecha(solicitud.fecha_programada , 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
      solicitud.tiempo_estimado = self.calcularDiasASegundos(solicitud.tiempo_estimado_dias) +
                                    self.calcularHorasASegundos(solicitud.tiempo_estimado_horas) +
                                    self.calcularMinutosASegundos(solicitud.tiempo_estimado_minutos)

      if(solicitud.tiempo_estimado == 0){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un tiempo estimado')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return
      }

      SolicitudInstalacionSrv.actualizar(solicitud).then(response => {
        iu.msg.success('Se actualizó correctamente')
        self.solicitud.nuevo_id_estado = self.solicitud.id_estado
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la solicitud de instalación'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    agregarTelefono() {
      var self = this

      if(self.numeroTelefono.length > 20){
        iu.msg.warning('El numero telefónico no puedo contener mas de 20 números.')
        self.$refs.numeroTelefonico.select()
        return
      }
      
      if(self.numeroTelefono.length == ''){
        iu.msg.warning('Se debe de registrar un numero.')
        self.$refs.numeroTelefonico.select()
        return
      }

      if(self.prefijo == null || self.prefijo == '') {
        iu.msg.warning('No se ha ingresado un prefijo telefónico')
      self.$refs.prefijo.focus()
      return
    }
      
      self.solicitud.telefonos.push({
        numero: self.numeroTelefono,
        prefijo: self.prefijo && self.prefijo.substring(0, 1) == '+'? self.prefijo : '+' + self.prefijo,
        tipo: self.tipoTelefono
      })
      
      self.numeroTelefono = ''
      self.tipoTelefono = 'celular'
    },

    asignarClienteASolicitud: function(cliente) {
      var self = this
      Object.assign(self.solicitud, {
        id_cliente: cliente.id,
        nombre: cliente.nombre,
        apellido_materno: cliente.apellido_materno,
        apellido_paterno: cliente.apellido_paterno,
        empresa: cliente.empresa,
        rfc: cliente.rfc,
        sexo: cliente.sexo,
        telefono: cliente.telefono,
        correo: cliente.correo,

        calle: cliente.calle,
        numero_exterior: cliente.numero_exterior,
        numero_interior: cliente.numero_interior,
        colonia: cliente.colonia,
        municipio: cliente.municipio,
        codigo_postal: cliente.codigo_postal,
        estado_residencia: cliente.estado_residencia,
        pais: cliente.pais,

        calle_instalacion: cliente.calle,
        numero_exterior_instalacion: cliente.numero_exterior,
        numero_interior_instalacion: cliente.numero_interior,
        colonia_instalacion: cliente.colonia,
        municipio_instalacion: cliente.municipio,
        codigo_postal_instalacion: cliente.codigo_postal,
        estado_residencia_instalacion: cliente.estado_residencia,
        pais_instalacion: cliente.pais
      })

      self.seleccionCliente = 0
    },

    asignarModo: function() {
      if (this.$route.path.indexOf('nueva') >= 0) this.modo = 'nueva'
      else this.modo = 'edicion'
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    cancelarSolicitud: function(motivo) {
      var self = this,
        idSolicitud = self.solicitud.id

      SolicitudInstalacionSrv.cancelar(idSolicitud, motivo).then(response => {
        iu.msg.success('Se canceló correctamente')
        self.cargarSolicitud()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puede cancelar la solicitud de instalació'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    // cargarApiKeyEnGoogleMaps: function() {
    //   return SistemaSrv.apiKeyGoogleMaps().then(response => {
    //     let key = response.data
    //     GoogleMapsLoader.KEY = key
    //   }).catch(error => {
    //     let mensaje
    //     try {
    //       mensaje = error.response.data.message
    //     } catch (e) {
    //       mensaje = ''
    //     }
    //     mensaje != '' ? iu.msg.error(mensaje): null
    //     console.log(error)
    //   })
    // },

    cargarMetodosPago () {
      let self = this

      MetodoPagoSrv.metodosJSON().then(response => {
        self.metodosPago = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarMesesIncluidos: function() {
      var self = this

      SistemaSrv.valorAjuste('meses_incluidos_en_instalacion').then(response => {
        self.solicitud.meses_incluidos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPlanes: function() {
      var self = this
      let params = { paginacion: false, activo: true }

      return ProductoSrv.planesServicioJSON(params).then(response => {
        self.planes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarPrecioInstalacion: function() {
      var self = this

      SistemaSrv.valorAjuste('precio_instalacion').then(response => {
        self.solicitud.importe = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarSolicitud: function() {
      var self = this

      return SolicitudInstalacionSrv.solicitudInstalacionJSON(self.id).then(response => {
        let solicitud = response.data

        Object.assign(self.solicitud, solicitud)
        self.solicitud.nuevo_id_estado = self.solicitud.id_estado
        self.solicitud.latitud = parseFloat(self.solicitud.latitud)
        self.solicitud.longitud = parseFloat(self.solicitud.longitud)

        self.solicitud.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[0]
        self.solicitud.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[1]
        self.solicitud.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[2]

        self.posicionInicial = {
          lat: parseFloat(solicitud.latitud),
          lng: parseFloat(solicitud.longitud)
        }

        self.lat_lng = parseFloat(solicitud.latitud)+','+parseFloat(solicitud.longitud)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarPaises: function() {
      var self = this
      
      let params = {
        datos_solicitados: ['id','nombre','prefijo_telefonico']
      }

      SistemaSrv.paisesJSON(params).then(response => {
        self.paises = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los paises'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPrefijoTelefonico: function() {
      var self = this

      SistemaSrv.ajustes(['prefijo_telefonico']).then(response => {
        let ajustes = response.data
        self.prefijo = ajustes.prefijo_telefonico
      })
    },

    cerrar: function() {
      this.$router.go(-1)
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },

    eliminarTelefono: function(index) {
      var self = this

      self.solicitud.telefonos.splice(index, 1)
    },

    esEditable: function() {
      if (
        !(
          this.solicitud.nuevo_id_estado == 3 ||
          this.solicitud.nuevo_id_estado == 4
        )
      ) {
        return true
      }
      return false
    },

    formatoFecha(fecha, formatoFecha = 'YYYY-MM-DD', formatoConvertir = 'DD/MM/YYYY') {
      return moment(fecha, formatoFecha).format(formatoConvertir)
    },

    guardar: function() {
      var self = this,
        solicitud = Object.assign({}, self.solicitud)

      self.bandera_spinner = true

      // Si no se pasó la verificacion de datos correctos, entonces se suspende la actualización
      if (!self.verificacionDatosCorrectos()) return

      // convertimos el formato fecha al formato de fecha deseado
      solicitud.fecha_programada = self.formatoFecha(solicitud.fecha_programada , 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
      solicitud.tiempo_estimado = self.calcularDiasASegundos(solicitud.tiempo_estimado_dias) +
                                    self.calcularHorasASegundos(solicitud.tiempo_estimado_horas) +
                                    self.calcularMinutosASegundos(solicitud.tiempo_estimado_minutos)

      if(solicitud.tiempo_estimado == 0){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un tiempo estimado')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return
      }

      // Si la dirección de instalacción es la misma que la del cliente,
      // se actualiza la dirección de instalación
      if (self.direccionInstalacionIgualADireccionCliente) {
        solicitud = Object.assign(solicitud, {
          calle_instalacion: solicitud.calle,
          numero_exterior_instalacion: solicitud.numero_exterior,
          numero_interior_instalacion: solicitud.numero_interior,
          colonia_instalacion: solicitud.colonia,
          municipio_instalacion: solicitud.municipio,
          codigo_postal_instalacion: solicitud.codigo_postal,
          estado_residencia_instalacion: solicitud.estado_residencia,
          pais_instalacion: solicitud.pais
        })
      }

      SolicitudInstalacionSrv.guardar(solicitud).then(response => {
        iu.msg.success('Se guardó correctamente')
        self.solicitud = Object.assign({}, self.solicitudInicial)
        self.solicitud.telefonos = []
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la solicitud'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    inicializarGoogleMaps: function() {
      var self = this
      
      if(self.modo == 'nueva') {
        SistemaSrv.posicionInicial().then(response => {
          let posicion = response.data

          self.posicionInicial = {
            lat: parseFloat(posicion.latitud),
            lng: parseFloat(posicion.longitud)
          }

          self.solicitud = Object.assign(self.solicitud, {
            latitud: parseFloat(posicion.latitud),
            longitud: parseFloat(posicion.longitud)
          })

          self.lat_lng = parseFloat(posicion.latitud)+','+parseFloat(posicion.longitud)
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = ''
          }
          mensaje != '' ? iu.msg.error(mensaje): null
          console.log(error)
        })
      } else {
        self.posicionInicial = Object.assign({
          lat: parseFloat(self.solicitud.latitud),
          lng: parseFloat(self.solicitud.longitud)
        })
        self.lat_lng = parseFloat(self.solicitud.latitud)+','+parseFloat(self.solicitud.longitud)
      }
    },

    iniciarMapa: function() {
      var self = this

      self.inicializarGoogleMaps()
    },

    imprimirSolicitud(solicitud) {
      let url = API+"/solicitudes-instalacion/"+solicitud.id+"/impresion/"+md5(solicitud.id.toString())
      window.open(url,'_blank')
    },

    mostrarPosicion: function(event) {
      var self = this 

      self.solicitud.latitud = event.latLng.lat()
      self.solicitud.longitud = event.latLng.lng()

      self.posicionInicial = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      self.lat_lng = event.latLng.lat()+','+event.latLng.lng()
    },

    actualizarPosicion: function() {
      var self = this, array = self.lat_lng.split(',')

      if(array.length == 1 || array.length > 2) {
        iu.msg.warning("No es una coordenada válida, ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.solicitud.latitud)+','+parseFloat(self.solicitud.longitud)
        return
      }
      let latitud = parseFloat(array[0]), longitud = parseFloat(array[1])

      if(!latitud || !longitud) {
        iu.msg.warning("Latitud o longitud no son coordenadas válidas; ingresé una coordenada correcta.")
        self.lat_lng = parseFloat(self.solicitud.latitud)+','+parseFloat(self.solicitud.longitud)
        return
      }

      self.solicitud.latitud = latitud
      self.solicitud.longitud = longitud

      self.posicionInicial = {
        lat: latitud,
        lng: longitud
      }

      self.lat_lng = latitud+','+longitud
    },

    preguntaCancelarSolicitud: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de solicitud",
        html: '¿Desea cancelar la solicitud de instalación?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntaMotivoCancelacion()
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaMotivoCancelacion: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Motivo de cancelación",
        html: '¿Por qué se canceló la instalación?',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
        allowOutsideClick: false
      }).then(text => {
        if (text.value) {
          self.cancelarSolicitud(text.value)
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    // refrescarMapa: function() {
    //   var self = this
    //   setTimeout(function() {
    //     self.google.maps.event.trigger(this.mapa, 'resize')
    //     self.mapa.setCenter(self.marker.getPosition())
    //   }, 200)
    // },

    sumaTotalServicios: function(servicios) {
      var suma = 0

      servicios.forEach(function(s, i) {
        suma += parseFloat(s.precio_publico)
      })

      return suma
    },

    verificacionDatosCorrectos: function() {
      var self= this,
          sld = this.solicitud

      if (sld.importe == null && sld.importe != 0){
        iu.msg.warning('El precio de instalación no puede estar vacío mínimo 0')
        self.$refs.importe.select()
        self.bandera_spinner = false
        return false
      }

      if (sld.anticipo == null && sld.anticipo != 0){
        iu.msg.warning('El anticipo no puede estar vacío mínimo 0')
        self.$refs.anticipo.select()
        self.bandera_spinner = false
        return false
      }

      if (sld.fecha_programada == null || sld.fecha_programada == '') {
        iu.msg.warning('Es necesario seleccionar una fecha para la instalación del servicio')
        self.$refs.fechaProgramada.select()
        self.bandera_spinner = false
        return false
      }

      if(sld.meses_incluidos > 60 || sld.meses_incluidos == null || sld.meses_incluidos == '') {
        iu.msg.warning('Los meses incluidos no pueden superar los 60 meses o 5 años, verifique el valor correctamente.')
        self.$refs.meses.focus()
        self.bandera_spinner = false
        return
      }

      if (sld.id_servicio == null) {
        iu.msg.warning('Es necesario seleccionar un servicio a contratar')
        self.$refs.id_servicio.focus()
        self.bandera_spinner = false
        return false
      }

      if (sld.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.tieneAlias('Calle') && sld.calle == '') {
        iu.msg.warning('Es necesario ingresar la '+self.cargarAliasCampo('Calle'))
        self.$refs.calle.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.tieneAlias('N° ext') && sld.numero_exterior == '') {
        iu.msg.warning('Es necesario ingresar el '+self.cargarAliasCampo('N° ext'))
        self.$refs.numero_exterior.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}